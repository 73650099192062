const initialState = {
  isAuthenticated: !!localStorage.getItem('token'),
  user: localStorage.getItem('user') || null,
  token: localStorage.getItem('token') || null,
  userGroupID: localStorage.getItem('userGroupID') ? Number(localStorage.getItem('userGroupID')) : null,
};
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('user', action.payload.username);
      localStorage.setItem('userGroupID', action.payload.userGroupID);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.username,
        token: action.payload.token,
        userGroupID: action.payload.userGroupID,
        profilePicture: action.payload.profilePicture,
      };
    case 'LOGOUT':
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.removeItem('userGroupID');
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        token: null,
        userGroupID: null,
      };
    default:
      return state;
  }
};

export default authReducer;
